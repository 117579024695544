import React, {useEffect} from 'react';
/**
 *
 *
 * @return {*}
 */
const Certificate = () => {
  useEffect(() => {
    window.top.postMessage('certificate_selected', '*');
  }, []);


  return (
    <>
      <div></div>
    </>
  );
};

export default Certificate;
